<template>
  <div>
    <v-layout style="display: flex; justify-content: center; align-items: center;"
      checkout-container ref="checkoutContainer">
      <v-layout checkout-content d-flex flex-column align-center>
        <v-tabs v-model="tab" bg-color="primary" hide-slider v-show="!showBonus">
          <v-tab v-for="(item, index) in tabs" :key="`tab_${item.slot}`" :value="index" hide-slider
            :disabled="disableTab" v-show="item.show">
            <div class="tab-icon">
              <i :class="`fal small fa-${item.icon}`" style="color: rgba(var(--theme-primaria))"></i>
            </div>
            <span style="color: rgba(var(--theme-primaria))">{{ item.title }}</span>
          </v-tab>
        </v-tabs>
        <v-card>
          <v-progress-linear :active="loading" :indeterminate="true" color="blue"></v-progress-linear>
          <v-card-text :style="`opacity: ${loading ? 0.6 : 1};`">
            <v-window v-model="tab" v-show="!showBonus">
              <v-window-item v-for="(item, index) in tabs" :key="index">
                <slot :name="item.slot"></slot>
              </v-window-item>
              
            </v-window>
            <v-window v-if="showBonus">
              <v-windows-item >
                <slot name="bonus"></slot>
              </v-windows-item>
            </v-window>
          </v-card-text>

          <v-card-actions class="box-shadow">
            <v-btn :disabled="loading" class="btn btn-border" @click="goBack">
              <i class="fal fa-angle-left"></i>
              Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" class="btn btn-primary" v-if="nextTab !== null && !showBonus" @click="checkOnNextButton()"
              :disabled="disableButton"
              :style="!disableButton ? { 'background-color': 'rgba(var(--theme-primaria)) !important' } : {}">
              Continuar
              <i class="fal fa-angle-right"></i>
            </v-btn>
            <v-btn :loading="loading" class="btn btn-border" v-if="nextTab !== null && showBonus" @click="goNext">
              Continuar com pacote atual
              <i class="fal fa-angle-right"></i>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>

    </v-layout>
  </div>


</template>


<style scoped>
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 1px solid #E0E1E2 !important;
}
</style>



<script>
// VUEX
import { mapGetters, mapActions } from "vuex";

// Enums
import { Routes } from "@/scripts/models/enums/routes.enum";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

export default {
  props: {
    config: Object,
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      tab: 0,
      showBonus: false,
      loading: false,
      step: 1,
      showThanks: false,
    };
  },
  created() {
    // console.log(this.tabs)
    // if (this.cartLocal && this.cartLocal.campaignProduct && this.cartLocal.campaignProduct.campaignProductQuestionList.length === 0) {
    //   this.tabs.splice(1, 1)
    // }

    this.getAppreciationList()
    this.refreshCampaignGifts()
  },
  computed: {
    ...mapGetters([
      "tabs",
      "cartItems",
      "itemsToCheckout",
      "reachedMinimumValue",
      "campaignDonationProduct",
      "campaignLandingPageConfig",
      "selectedGift",
      "appreciationList",
      "campaignGifts",
      "checkoutValue",
      "cartLocal"
    ]),
    lastTab() {
      return this.tabs.length - 1;
    },
    nextTab() {
      if (this.tab === null) {
        const nextTab = this.tabs[1];
        return nextTab ? 1 : null;
      }

      let nextTab = this.tabs[this.tab + 1];
      let nTab = this.tab + 1
      
      if (nextTab && !nextTab.show) {
        nextTab = this.tabs[this.tab + 2];
        nTab = this.tab + 2
      }

      return nextTab ? nTab : null;
    },

    previousTab() {
      if (this.tab === null) {
        return null;
      }

      let nextTab = this.tabs[this.tab - 1];
      let nTab = this.tab - 1

      if (nextTab && !nextTab.show) {
        nextTab = this.tabs[this.tab - 2];
        nTab = this.tab - 2
      }

      return nextTab ? nTab : null;
    },
    disableTab() {
      return (this.tab === 0 && (this.reachedMinimumValue === false || this.checkoutValue === 0)) || 
      (this.tab === 1 && (this.answersQuantity !== this.questionsQuantity)) ||
      (this.itemsToCheckout.length !== this.cartItems.length) || (this.itemsToCheckout.length === 0)
    },
    disableButton() {
      return (this.tab === 1 && (this.answersQuantity !== this.questionsQuantity)) ||
        (this.itemsToCheckout.length !== this.cartItems.length) || (this.itemsToCheckout.length === 0)
    },
    
    questionsQuantity() {
      let quantity = 0;
      if (!this.campaignDonationProduct) {
        for (const item of this.itemsToCheckout) {
          if (item.donationProduct !== null && item.donationProduct.donationProductQuestionList)
            quantity += item.donationProduct.donationProductQuestionList.length
        }
        return quantity;
      }

      if (this.campaignDonationProduct && this.campaignDonationProduct.campaignProductQuestionList)
        quantity = this.campaignDonationProduct.campaignProductQuestionList.length

      return quantity
    },
    answersQuantity() {
      let quantity = 0;
      if (!this.campaignDonationProduct) {
        for (const item of this.itemsToCheckout) {
          if (item.donationProduct !== null && item.donationProduct.donationProductQuestionList !== null)
            item.donationProduct.donationProductQuestionList.forEach((question) => {

              if (question.answer !== null && question.answer !== '0') quantity++;
            })
        }
        return quantity
      }

      if (this.campaignDonationProduct && this.campaignDonationProduct.campaignProductQuestionList)
        this.campaignDonationProduct.campaignProductQuestionList.forEach((question) => {
          if (question.answer !== null && question.answer !== '0') quantity++;
        })

      return quantity
    },
    minimumValue() {
      return this.campaignDonationProduct ? this.campaignDonationProduct.initialValue : this.minimumDonationValue
    },
  },
  watch: {
    campaignDonationProduct: {
      handler(newVal) {
        if(newVal)
          this.setTabs()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'getAppreciationList',
      'refreshCampaignGifts',
      'setTabs'
    ]),
    mockRequest(returnValue) {
      this.loading = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.loading = false;
          resolve(returnValue);
        }, 1000);
      });
    },
    checkOnNextButton() {
      this.setTabs()

      if (this.tab === 0 && (this.reachedMinimumValue === false || this.checkoutValue === 0)) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          `O Valor mínimo para esta doação é de ${this.formatterHelper.formatMoneyWithCents(this.minimumValue)}`
        );
      } else {
        this.goNext()
      }
    },
    async goNext() {
      if (this.nextTab === null || this.loading) {
        return;
      }

      if (this.config.bonus && !this.showBonus && !this.selectedGift && this.tab === 0) {
        this.showBonus = this.hasNextAppreciations();
        if (this.showBonus) {
          return;
        }
      }

      if (this.selectedGift) {
        this.showBonus = false
      }

      await this.mockRequest();
      this.tab = this.nextTab;
      this.showBonus = false;
      this.$emit('goNext')
    },

    goBack() {
      if (this.tab != 0 && this.previousTab === null || this.loading) {
        this.showBonus = false
        return;
      }

      if (this.tab != 0 && this.selectedGift) {
        this.showBonus = false
      }

      if (this.tab === 0 && !this.showBonus) {
        this.$router.push({ name: Routes.app.CampaignLandingPage });
      }

      this.tab = this.previousTab;
      this.showBonus = false;
    },
    hasNextAppreciations() {
      if (!this.appreciationList)
        return false
      const result = this.appreciationList.filter((appreciation) => {
        const differenceBetween = (appreciation.firstValue - this.checkoutValue)
        appreciation.productValueForAppreciation = differenceBetween
        appreciation.product = this.campaignGifts.find((product) => {
          return (differenceBetween >= product.initialValue) && (differenceBetween <= product.finalValue) && (product.active === true)
        })
        if (this.appreciation)
          return ((this.appreciation.lastValue < appreciation.firstValue) && this.appreciation.id !== appreciation.id && appreciation.product)
        else
          return (this.checkoutValue < appreciation.firstValue) && appreciation.product
      })
      return (result.length > 0)
    },
  },
};
</script>
